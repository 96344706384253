import React from "react"
import { NotFound } from "../components/NotFound/NotFound"
import { Seo } from "../components/Seo/Seo"

const NotFoundTemplate = props => {
  return (
    <>
      <Seo
        title="Not found"
        description={"This page is a not found page"}
        domain={props.pageContext.configuration.domain}
        lang={"en"}
        indexable={false}
      />
      <NotFound />
    </>
  )
}

export default NotFoundTemplate
